import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Link from "../link"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const Container = styled.article`
  .-img {
    @media ${(props) => props.theme.xsmallDown} {
      margin-bottom: 3em;
    }

    a:hover {
      opacity: 0.8;
    }

    a:active {
      opacity: 0.6;
    }
  }

  p {
    margin-bottom: 1em;
    opacity: 0.6;
  }
`

const ArticleFull = ({
  data: {
    slug,
    acf: { title, description, illustration },
  },
}) => {
  return (
    <Container>
      <Row>
        <Cell size={7} pushX={2} mq={{ xsmallDown: { size: 14, pushX: 4.5 } }}>
          <div className="-img">
            <Link to={`/${slug}`}>
              <img
                src={`/product-illustrations/${illustration}.svg`}
                alt={title}
              />
            </Link>
          </div>
        </Cell>

        <Cell size={10} pushX={3} mq={{ xsmallDown: { size: 23, pushX: 0 } }}>
          <h3 className="styled-p-leading">{title}</h3>

          <div
            className="styled styled--large-type"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <div className="styled-p-leading">
            <StoreConsumer>
              {({ translations }) => (
                <Link
                  to={`/${slug}`}
                  className="styled-a"
                  dangerouslySetInnerHTML={{
                    __html: translations[`find_out_more`],
                  }}
                />
              )}
            </StoreConsumer>
          </div>
        </Cell>
      </Row>
    </Container>
  )
}

ArticleFull.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ArticleFull
