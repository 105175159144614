import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Link from "../link"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const Container = styled.article`
  .-img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 3em;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
    }

    a:hover {
      opacity: 0.8;
    }

    a:active {
      opacity: 0.6;
    }
  }

  p {
    margin-bottom: 1em;
    opacity: 0.6;
  }
`

const Article = ({
  oneThird,
  data: {
    slug,
    acf: { title, description, illustration },
  },
}) => {
  return (
    <Container>
      <Cell
        size={17}
        pushX={oneThird ? 0 : 3}
        mq={{ xsmallDown: { size: 14, pullX: 0, pushX: 4.5 } }}
      >
        <div className="-img">
          <Link to={`/${slug}`}>
            <img
              src={`/product-illustrations/${illustration}.svg`}
              alt={title}
            />
          </Link>
        </div>
      </Cell>

      <h3 className="styled-p-leading">{title}</h3>

      <div
        className="styled styled--large-type"
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <div className="styled-p-leading">
        <StoreConsumer>
          {({ translations }) => (
            <Link
              to={`/${slug}`}
              className="styled-a"
              dangerouslySetInnerHTML={{
                __html: translations[`find_out_more`],
              }}
            />
          )}
        </StoreConsumer>
      </div>
    </Container>
  )
}

Article.propTypes = {
  data: PropTypes.object.isRequired,
  oneThird: PropTypes.bool,
}

export default Article
