import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Article from "./article"
import ArticleFull from "./article-full"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Spacer from "../dotgrid/spacer"
import { StoreConsumer } from "../../store"

const dots = { color: `#fff`, opacity: 0.2 }

const Container = styled.section`
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
`

const Explore = ({ data: { edges } }) => {
  const products = []
  edges.forEach(({ node }) => (products[node.acf.machineName] = node))

  return (
    <Container>
      <Grid noBottomPadding>
        <Row pullY={1} mq={{ xsmallDown: { pullY: 2 } }}>
          <Cell size={23}>
            <StoreConsumer>
              {({ translations }) => (
                <h2
                  className="styled-h3"
                  dangerouslySetInnerHTML={{
                    __html: translations[`explore_title`],
                  }}
                />
              )}
            </StoreConsumer>
          </Cell>
        </Row>
      </Grid>

      <Grid noTopPadding noBottomPadding>
        <Row pullY={2} mq={{ xsmallDown: { pullY: 4 } }}>
          <Spacer dots={dots} size={4} mq={{ xsmallDown: { size: 2 } }} />
        </Row>
      </Grid>

      <Grid noTopPadding noBottomPadding>
        <Row>
          {products[`access`] && (
            <Cell size={9} mq={{ xsmallDown: { size: 23 } }}>
              <Article data={products[`access`]} />
            </Cell>
          )}

          {products[`access-pro`] && (
            <Cell
              size={9}
              pushX={3}
              mq={{ xsmallDown: { size: 23, pushX: 0, pushY: 6 } }}
            >
              <Article data={products[`access-pro`]} />
            </Cell>
          )}
        </Row>
      </Grid>

      <Grid noTopPadding noBottomPadding>
        <Row pushY={2} pullY={2} mq={{ xsmallDown: { pushY: 4, pullY: 4 } }}>
          <Spacer dots={dots} size={3} mq={{ xsmallDown: { size: 2 } }} />
        </Row>
      </Grid>

      {products[`enterprise`] && (
        <React.Fragment>
          <Grid noTopPadding noBottomPadding>
            <Row>
              <Cell size={23}>
                <ArticleFull data={products[`enterprise`]} />
              </Cell>
            </Row>
          </Grid>

          <Grid noTopPadding noBottomPadding>
            <Row
              pushY={2}
              pullY={2}
              mq={{ xsmallDown: { pushY: 4, pullY: 4 } }}
            >
              <Spacer dots={dots} size={3} mq={{ xsmallDown: { size: 2 } }} />
            </Row>
          </Grid>
        </React.Fragment>
      )}

      {(products[`track`] || products[`connect`]) && (
        <Grid noTopPadding noBottomPadding>
          <Row>
            {products[`track`] && (
              <Cell size={9} mq={{ xsmallDown: { size: 23 } }}>
                <Article data={products[`track`]} />
              </Cell>
            )}

            {products[`connect`] && (
              <Cell
                size={9}
                pushX={3}
                mq={{ xsmallDown: { size: 23, pushX: 0, pushY: 6 } }}
              >
                <Article data={products[`connect`]} />
              </Cell>
            )}
          </Row>
        </Grid>
      )}

      {(products[`engage`] ||
        products[`buyer-api`] ||
        products[`supplier-api`]) && (
        <React.Fragment>
          <Grid noTopPadding noBottomPadding>
            <Row
              pushY={2}
              pullY={2}
              mq={{ xsmallDown: { pushY: 4, pullY: 4 } }}
            >
              <Spacer dots={dots} size={3} mq={{ xsmallDown: { size: 2 } }} />
            </Row>
          </Grid>

          <Grid noTopPadding noBottomPadding>
            <Row>
              {products[`buyer-api`] && (
                <Cell
                  size={7}
                  mq={{ xsmallDown: { size: 23, pushX: 0, pushY: 6 } }}
                >
                  <Article data={products[`buyer-api`]} oneThird={true} />
                </Cell>
              )}

              {products[`supplier-api`] && (
                <Cell
                  size={7}
                  pushX={1}
                  mq={{ xsmallDown: { size: 23, pushX: 0, pushY: 6 } }}
                >
                  <Article data={products[`supplier-api`]} oneThird={true} />
                </Cell>
              )}

              {products[`engage`] && (
                <Cell size={7} pushX={1} mq={{ xsmallDown: { size: 23 } }}>
                  <Article data={products[`engage`]} oneThird={true} />
                </Cell>
              )}
            </Row>
          </Grid>
        </React.Fragment>
      )}

      <Grid noTopPadding>
        <Row pushY={2} mq={{ xsmallDown: { pushY: 4 } }}>
          <Spacer dots={dots} size={4} mq={{ xsmallDown: { size: 2 } }} />
        </Row>
      </Grid>
    </Container>
  )
}

Explore.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Explore
